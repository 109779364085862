import React from "react";

import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./ActionButtons.styles";

import { ContactUsButton } from "@src/components/CommonButtons";
import { HomePageButton } from "./HomePageButton";

export type ActionButtonsProps = WithStyles<typeof styles> & {};

class ActionButtons extends React.PureComponent<ActionButtonsProps> {
  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.buttonsContainer}>
        <HomePageButton className={classes.button} />
        <ContactUsButton className={classes.button} />
      </div>
    );
  }
}

export default withStyles(styles)(ActionButtons);
