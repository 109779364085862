import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    animation: {
      minWidth: "100%",
    },
    reverted: {
      transform: "rotateY(180deg)",
    },
  });
