import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageProps } from "@src/types";
import { t } from "@src/utils/translations";

import NotFoundTemplate from "./NotFoundTemplate";

export type IndexPageProps = PageProps & {};

class IndexPage extends React.Component<IndexPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Not found")}
          description={t("This is not the page you are looking for.")}
          keywords={[t("not found")]}
        />
        <NotFoundTemplate />
      </IndexLayout>
    );
  }
}

export default IndexPage;
