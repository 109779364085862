import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(0, 5),
    },
    contentWrapper: {
      margin: theme.spacing(19, 0, 15),
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(24),
      },
    },
    animation: {
      width: "280px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 auto",
      },
    },
    title: {
      margin: theme.spacing(7, 0, 2),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(5),
      },
    },
  });
