import React from "react";

import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { styles } from "./NotFoundTemplate.styles";

import { T } from "react-targem";

import Animation from "@src/components/Animation";
import ActionButtons from "./ActionButtons";
import dataJson from "./data.json";

export type NotFoundTemplateProps = WithStyles<typeof styles> & {};

class NotFoundTemplate extends React.Component<NotFoundTemplateProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Container className={classes.container}>
        <div className={classes.contentWrapper}>
          <Animation
            animationData={dataJson}
            loop
            className={classes.animation}
          />
          <Typography className={classes.title} variant="h3">
            <T message="Oops!" />
          </Typography>
          <Typography variant="h5">
            <T message="This is not the page you are looking for." />
          </Typography>
          <ActionButtons />
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(NotFoundTemplate);
