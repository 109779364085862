import React from "react";
import { T } from "react-targem";

import ButtonLink from "@src/components/ButtonLink";

export type HomePageButtonProps = {
  className?: string;
};

export class HomePageButton extends React.PureComponent<HomePageButtonProps> {
  public render() {
    return (
      <ButtonLink to="/" variant="contained" color="secondary" {...this.props}>
        <T message="Go to home page" />
      </ButtonLink>
    );
  }
}
