import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    buttonsContainer: {
      display: "flex",
      margin: theme.spacing(5, -0.5, 0),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(4),
        flexWrap: "wrap",
        justifyContent: "center",
      },
    },
    button: {
      margin: theme.spacing(1, 0.5),
    },
  });
