import * as React from "react";

import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import LottieWeb, { AnimationItem } from "lottie-web";
import { withLocale, WithLocale } from "react-targem";
import { styles } from "./Animation.styles";
interface Props extends WithLocale, WithStyles<typeof styles> {
  /**
   * @param animationData - data from JSON file
   */
  animationData: {
    [x: string]: unknown;
  };
  loop?: boolean;
  className?: string;
}

class Animation extends React.Component<Props> {
  public animation: AnimationItem | null = null;
  public animationContainer = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    const { loop, animationData } = this.props;
    if (!this.animationContainer.current) {
      return;
    }
    this.animation = LottieWeb.loadAnimation({
      container: this.animationContainer.current,
      renderer: "svg",
      loop: loop !== false,
      animationData,
    });
  }

  public componentWillUnmount() {
    if (this.animation) {
      this.animation.destroy();
    }
    this.animation = null;
  }

  public render() {
    const { direction, className, classes } = this.props;
    return (
      <div
        ref={this.animationContainer}
        className={cn(
          className,
          classes.animation,
          direction === "rtl" ? classes.reverted : undefined,
        )}
      />
    );
  }
}

export default withLocale(withStyles(styles)(Animation));
